import { Box, Button, Container, Divider, Grid, Typography } from '@material-ui/core';
import Switch from '../../../../../../components/Inputs/Switch/Switch';
import TextInput from '../../../../../../components/Inputs/TextInput/TextInput';
import Dropdown from '../../../../../../components/Inputs/Dropdown/Dropdown';
import '../Controllers.scss';
import { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { CONTROLLER_LOCKING_MECHANISMS } from '../../../../../../constants/list/AccessPointTypeList';

const INCORRECT_ERROR = "Serial number doesn't support the configuration";

const ControllerChannel = ({
  channelenabled,
  channelName,
  value,
  onChange,
  configuration,
  onEnable,
  validSerials,
  setError,
  module,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isHiddenMechanism, setIsHiddenMechanism] = useState(true);
  const [channelValue, setChannelValue] = useState(value);
  const [isEnabled, setIsEnabled] = useState(false);
  const [serialNumber, setSerialNumber] = useState({ value: '', description: '', error: '' });

  const valueChanged = (field, val) => {
    setChannelValue({
      ...channelValue,
      [field]: val,
    });
  };

  useEffect(() => {
    setIsEnabled(channelenabled);
  }, [channelenabled]);

  useEffect(() => {
    setChannelValue(value);
  }, [value, channelenabled]);

  useEffect(() => {
    onChange(channelValue);
    onEnable(isEnabled);
  }, [channelValue, isEnabled, onChange, onEnable]);

  function checkError(stringValue) {
    if (!validSerials.includes(stringValue?.substring(0, 4))) {
      return INCORRECT_ERROR;
    } else if (stringValue.length !== 14) {
      return 'Please enter a valid Serial Number';
    } else {
      return '';
    }
  }

  const handleSerialDescription = (stringValue) => {
    const switchCase = stringValue.substring(0, 4);
    let description = '';
    switch (String(switchCase)) {
      case '1001':
      case '1002':
      case '1016':
      case '101F':
      case '1021':
      case '1026':
      case '1032':
      case '1028':
      case '1029':
      case '1033':
      case '1034':
        description = 'Mobile/Card';
        break;
      case '1023':
      case '1015':
        description = 'Mobile/Card/Fingerprint';
        break;
      case '1024':
      case '1025':
      case '103D':
        description = 'Face/Card';
        break;
      case '1027':
        description = 'Mobile';
        break;
      case '1038':
        description = 'Mobile/QR/Card';
        break;
      default:
        break;
    }

    let error = '';

    error = checkError(stringValue);

    setSerialNumber({ description: description, value: stringValue, error: error });
    valueChanged('serialNumber', stringValue);
    setError(error);
  };

  const lockingMechanismValue = channelValue?.lockingMechanism;
  const defaultLockingMechanism = CONTROLLER_LOCKING_MECHANISMS.find(
    (item) => item.value === lockingMechanismValue,
  );

  return (
    <Grid container spacing={2} style={{ marginTop: '1rem' }} className="channel">
      <Grid item sm={12}>
        <Container disableGutters style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={isEnabled}
            name="channel1"
            color="primary"
            onChange={() => {
              setIsEnabled(!isEnabled);
              // onEnable(isEnabled);
            }}
            disabled={module === 'edit'}
          />
          <Typography variant="h6" style={{ marginLeft: '0.6rem' }}>
            {channelName}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            style={{ marginLeft: 'auto' }}
            color="secondary"
            onClick={() => setIsHidden(!isHidden)}
            endIcon={isHidden ? <ExpandMore /> : <ExpandLess />}
          >
            {isHidden ? 'Show' : 'Hide'} details
          </Button>
        </Container>
        <Divider />
        {!isHidden && (
          <>
            <Grid item sm={12}>
              <TextInput
                placeholder="Access Point Name"
                value={channelValue?.accessPointName}
                onChange={(e) => valueChanged('accessPointName', e.target.value)}
              />
            </Grid>
            {configuration.value === 3 && (
              <Grid item sm={12}>
                <TextInput
                  maxLength={14}
                  placeholder="Entry Device Serial Number"
                  value={channelValue?.serialNumber}
                  description={serialNumber?.description}
                  error={serialNumber?.error}
                  onChange={(e) => handleSerialDescription(e.target.value)}
                  disabled={module === 'edit' && channelValue?.serialNumber !== ''}
                />
              </Grid>
            )}
            <Divider style={{ marginTop: '1rem' }} />
            <Grid item sm={6}>
              <Dropdown
                name="lockingMechanism"
                placeholder="Locking Mechanism"
                data={CONTROLLER_LOCKING_MECHANISMS}
                value={
                  defaultLockingMechanism
                    ? defaultLockingMechanism.name
                    : channelValue?.lockingMechanism?.name
                }
                onChange={(data) =>
                  valueChanged('lockingMechanism', {
                    name: data.name,
                    value: data.value,
                  })
                }
                disabled={module === 'edit'}
              />
            </Grid>
            <Grid item sm={12}>
              <div className="well">
                <Typography className="title">Relay Settings</Typography>
                {channelValue?.lockingMechanism ? (
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 'auto' }}
                    color="secondary"
                    onClick={() => setIsHiddenMechanism(!isHiddenMechanism)}
                    endIcon={isHiddenMechanism ? <ExpandMore /> : <ExpandLess />}
                  >
                    {isHiddenMechanism ? 'Show' : 'Hide'} details
                  </Button>
                ) : (
                  <Typography variant="caption" style={{ fontWeight: 'normal' }}>
                    No Locking Mechanism Selected
                  </Typography>
                )}
              </div>
            </Grid>
            {!isHiddenMechanism && channelValue?.lockingMechanism && (
              <Grid container>
                <Grid item sm={12}>
                  <Divider style={{ marginBottom: '1rem' }} />
                </Grid>
                <Grid item sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>Relay On Time</Typography>
                </Grid>
                <Grid item className="relay" sm={2}>
                  <TextInput
                    type="number"
                    value={channelValue?.relayTime === 0 ? '' : channelValue?.relayTime}
                    onChange={(e) => valueChanged('relayTime', Number(e.target.value))}
                  />
                </Grid>
                <Grid
                  item
                  sm={4}
                  style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem' }}
                >
                  <Typography variant="subtitle2">Seconds</Typography>
                </Grid>
                {channelValue?.relayTime === 0 ? (
                  <Box width="80%" textAlign="right" marginTop={2}>
                    <Typography style={{ color: 'red' }}> Relay time cannot be 0</Typography>
                  </Box>
                ) : (
                  <></>
                )}
                <Grid item sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>Invert relay logic</Typography>
                </Grid>
                <Grid item sm={6}>
                  <Container style={{ marginTop: '1rem' }}>
                    <Switch
                      checked={channelValue?.invertRelay}
                      color="primary"
                      onChange={() =>
                        valueChanged(
                          'invertRelay',
                          channelValue?.invertRelay === true ? false : true,
                        )
                      }
                    />
                  </Container>
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="body2" className="danger">
                    Enabling this changes the logic of the relay and you will have to wire fail-safe
                    locks to NO and fail-secure to NC. This will unlock the doors if the Spintly
                    reader/controller fails.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ControllerChannel;
