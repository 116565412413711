import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

//components
import TextInput from '../../../../../../components/Inputs/TextInput';
import Dropdown from '../../../../../../components/Inputs/Dropdown';
import OutlineButton from '../../../../../../components/OutlineButton/OutlineButton';
import Switch from '../../../../../../components/Inputs/Switch/Switch';
import Button from '../../../../../../components/Buttons/Button';

//styles
import styles from './styles.module.scss';

// redux actions
import {
  doCreateOrganisationAccessPoint,
  doUpdateOrganisationAccessPoint,
} from '../../../../../../redux/thunks/organisation';
import { selectCurrentPartner } from '../../../../../../redux/selectors/authentication';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
// import Radio from '../../../../../../components/Inputs/Radio/Radio';

const INCORRECT_ERROR = "Serial number doesn't support the configuration";

const AccessPointConfiguration = ({
  accessPointName,
  installationMethod,
  site,
  network,
  configuration,
  lockingMechanisms,
  validSerials,
  controlDisable,
  onClose,
  handleClick,
  popupTitle,
}) => {
  const dispatch = useDispatch();

  const { customerId } = useParams();
  const { currentUser } = useSelector((state) => ({
    currentUser: selectCurrentPartner(state),
  }));

  const [entryDeviceSerialNum, setEntryDeviceSerialNum] = useState({
    value: '',
    description: '',
    error: '',
  });
  const [exitDeviceSerialNum, setExitDeviceSerialNum] = useState({
    value: '',
    description: '',
    error: '',
  });
  const [controllerDeviceSerialNum, setControllerDeviceSerialNum] = useState({
    value: '',
    description: '',
    error: '',
  });
  const [lockingMech, setLockingMech] = useState({
    name: '',
    value: '',
    relayTime: '',
  });
  const [relayTime, setRelayTime] = useState(0);
  const [inveteredRelayLogic, setInveteredRelayLogic] = useState(false);
  const [attendanceEnabled, setAttendanceEnabled] = useState(false);
  // const [doorSensorEnabled, setDoorSensorEnabled] = useState(false);
  // const [normallyOpen, setNormallyOpen] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [relayTimeError, setRelayTimeError] = useState('');

  // loading
  // const [submitting, setSubmitting] = useState(false);

  const [toggleRelaytime, setToggleRelayTime] = useState(true);

  // useEffect(() => {
  //   if (entryDeviceSerialNum !== exitDeviceSerialNum) {
  //     controlDisable(true);
  //   } else if (entryDeviceSerialNum === '') {
  //     controlDisable(true);
  //   } else controlDisable(false);
  // }, [exitDeviceSerialNum, entryDeviceSerialNum]);

  useEffect(() => {
    let disabledValue = false;
    if (configuration.formOptions.entry) {
      if (entryDeviceSerialNum.value === '' || entryDeviceSerialNum.error !== '') {
        disabledValue = true;
      }
    }

    if (configuration.formOptions.exit) {
      if (exitDeviceSerialNum.value === '' || exitDeviceSerialNum.error !== '') {
        disabledValue = true;
      }
      if (
        entryDeviceSerialNum?.value?.substring(0, 4) !== exitDeviceSerialNum?.value?.substring(0, 4)
      ) {
        disabledValue = true;
      }
      if (entryDeviceSerialNum?.value === exitDeviceSerialNum?.value) {
        disabledValue = true;
      }
    }

    if (configuration.formOptions.control) {
      if (controllerDeviceSerialNum.value === '' || controllerDeviceSerialNum.error !== '') {
        disabledValue = true;
      }
    }

    if (configuration.formOptions?.lockingMechanism && lockingMech.value === '') {
      disabledValue = true;
    }

    if (configuration?.formOptions?.relayTime && relayTime < 1) {
      disabledValue = true;
    }

    if (relayTimeError !== '') {
      disabledValue = true;
    }
    setDisabled(disabledValue);
  }, [
    configuration.formOptions.entry,
    configuration.formOptions.exit,
    configuration.formOptions?.control,
    configuration.formOptions?.lockingMechanism,
    configuration?.formOptions?.relayTime,
    entryDeviceSerialNum?.value,
    exitDeviceSerialNum?.value,
    lockingMech?.value,
    controllerDeviceSerialNum,
    relayTime,
    relayTimeError,
    entryDeviceSerialNum.error,
    exitDeviceSerialNum.error,
  ]);

  const setRelayValue = useCallback((value) => {
    if (value <= 240) {
      setRelayTime(value);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const devices = [];
    if (configuration?.formOptions?.entry)
      devices.push({ serialNumber: entryDeviceSerialNum?.value, deviceType: 1 });
    if (configuration?.formOptions?.exit)
      devices.push({ serialNumber: exitDeviceSerialNum?.value, deviceType: 2 });
    if (configuration?.formOptions?.control)
      devices.push({ serialNumber: controllerDeviceSerialNum?.value });

    const payload = [
      {
        name: accessPointName,
        installationMethod: installationMethod?.value,
        configuration: configuration?.value,
        siteId: site?.value,
        networkId: network.id,
        devices: devices,
        ...(configuration?.formOptions?.lockingMechanism && {
          lockingMechanism: lockingMech?.value,
        }),
        ...(configuration?.formOptions?.doorSensor && {
          doorSensorSettings: {
            doorSensorEnable: false,
            doorSensorType: 1,
          },
        }),
        ...(configuration?.formOptions?.relayTime && {
          relaySettings: {
            relayOnTime: Number(relayTime),
            invertRelayLogic: inveteredRelayLogic,
          },
          ...(configuration?.formOptions?.attendanceAvailable && {
            enableAttendance: attendanceEnabled,
          }),
        }),
      },
    ];
    if (popupTitle === 'Add') {
      handleCreateOrganisation(payload).then((response) => {
        if (response.type === 'ORGANISATION_ACCESS_POINT_CREATE_SUCCESS') {
          onClose();
        }
        // setSubmitting(false);
      });
    } else {
      handleUpdateOrganisation(payload).then((response) => {
        // setSubmitting(false);
        onClose();
      });
    }

    onClose();
  };

  const handleCreateOrganisation = (payload) => {
    return dispatch(
      doCreateOrganisationAccessPoint({
        partnerId: currentUser.partnerId,
        networkId: network.id,
        organisationId: customerId,
        payload,
        callback: onClose,
      }),
    );
  };

  const handleUpdateOrganisation = (payload) => {
    return dispatch(
      doUpdateOrganisationAccessPoint({
        accessPointId: payload.id,
        partnerId: currentUser.partnerId,
        networkId: network.id,
        organisationId: customerId,
        payload,
        callback: onClose,
      }),
    );
  };

  const renderRelayTime = useCallback(() => {
    return (
      <div className={styles.relay_container}>
        <div className={styles.header}>
          <h1 className={styles.header__title}>
            {configuration.value === 6 ? 'Door Settings' : 'Relay Settings'}
          </h1>
          {configuration.value !== 6 ? (
            <>
              {lockingMech?.name === '' && configuration?.formOptions?.relayTime ? (
                <p className={styles.access_alert}>No locking mechanism selected</p>
              ) : (
                <>
                  {configuration?.formOptions?.relayTime ? (
                    <OutlineButton
                      onClick={(e) => {
                        e.preventDefault();
                        setToggleRelayTime(!toggleRelaytime);
                      }}
                      type="outlined"
                      variant="outlined"
                      buttonSmaller
                      text="Hide Details"
                    />
                  ) : (
                    <p className={styles.access_alert}>No settings available</p>
                  )}
                </>
              )}
            </>
          ) : (
            <OutlineButton
              onClick={(e) => {
                e.preventDefault();
                setToggleRelayTime(!toggleRelaytime);
              }}
              type="outlined"
              variant="outlined"
              buttonSmaller
              text="Hide Details"
            />
          )}
        </div>

        {toggleRelaytime && (lockingMech?.name !== '' || configuration.value === 6) && (
          <div className={styles.relay_container__body}>
            <br />
            <div className={styles.row}>
              <div className={styles.column}>
                <p>{configuration.value === 6 ? 'Door open duration' : 'Relay on Time'}</p>
              </div>
              <div className={styles.column}>
                <div className={styles.relay_input}>
                  <div className={styles.relay_input_container}>
                    <TextInput
                      name=""
                      value={relayTime}
                      type={'number'}
                      min="1"
                      max="240"
                      onChange={(e) => {
                        if (e.target.value <= 0) {
                          setRelayTimeError('Relay time cannot be set to 0');
                        } else {
                          setRelayTimeError('');
                        }
                        setRelayValue(e.target.value);
                      }}
                    />
                    <p className={styles.allowedText}>Allowed range is 1-240 seconds.</p>
                  </div>
                  <p>Seconds</p>
                </div>
              </div>
            </div>
            <br />
            <br />
            {relayTimeError !== '' ? (
              <Box width="80%">
                <Typography style={{ color: 'red', textAlign: 'right' }}>
                  {relayTimeError}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            {configuration.value !== 6 && (
              <>
                <div className={styles.row}>
                  <div className={styles.column}>
                    <p>Invert relay logic</p>
                  </div>
                  <div className={styles.column}>
                    <Switch
                      checked={inveteredRelayLogic}
                      onChange={() => setInveteredRelayLogic(!inveteredRelayLogic)}
                    />
                  </div>
                </div>
                <p className={styles.access_alert}>
                  Enabling this changes the logic of the relay and you will have to wire fail-safe
                  locks to NO and fail-secure to NC. This will unlock the doors if the Spintly
                  reader/controller fails.
                </p>
              </>
            )}
          </div>
        )}
      </div>
    );
  }, [
    toggleRelaytime,
    relayTime,
    inveteredRelayLogic,
    configuration,
    lockingMech,
    setRelayValue,
    relayTimeError,
  ]);

  const selectLockingMechanism = useCallback((data) => {
    setRelayTime(data?.relayTime ? data?.relayTime : 0);
    setLockingMech({ name: data.name, value: data.value });
  }, []);

  function checkError(value, field) {
    if (field === 'entry') {
      if (!validSerials.entry.includes(value?.substring(0, 4))) {
        return INCORRECT_ERROR;
      } else if (value.length !== 14) {
        return 'Please enter a valid Serial Number';
      } else {
        return '';
      }
    }
    if (field === 'exit') {
      if (!validSerials.exit.includes(value?.substring(0, 4))) {
        return INCORRECT_ERROR;
      } else if (value.length !== 14) {
        return 'Please enter a valid Serial Number';
      } else {
        return '';
      }
    }
    if (field === 'controller') {
      if (!validSerials.control.includes(value?.substring(0, 4))) {
        return INCORRECT_ERROR;
      } else if (value.length !== 14) {
        return 'Please enter a valid Serial Number';
      } else {
        return '';
      }
    }
  }

  const handleSerialDescription = (value, field) => {
    const switchCase = value.substring(0, 4);
    let description = '';
    switch (String(switchCase)) {
      case '1001':
      case '1002':
      case '1016':
      case '101F':
      case '1021':
      case '1026':
      case '1032':
      case '1028':
      case '1029':
      case '1033':
      case '1034':
      case '103B':
      case '103A':
        description = 'Mobile/Card';
        break;
      case '1023':
      case '1015':
        description = 'Mobile/Card/Fingerprint';
        break;
      case '1024':
      case '1025':
      case '103D':
        description = 'Face/Card';
        break;
      case '1027':
        description = 'Mobile';
        break;
      case '1038':
        description = 'Mobile/QR/Card';
        break;
      default:
        break;
    }

    let error = '';

    error = checkError(value, field);

    if (field === 'entry') {
      setEntryDeviceSerialNum({ description: description, value: value, error: error });
    }
    if (field === 'exit') {
      setExitDeviceSerialNum({ description: description, value: value, error: error });
    }
    if (field === 'controller') {
      setControllerDeviceSerialNum({ description: description, value: value, error: error });
    }
  };

  return (
    <div>
      {configuration.formOptions.entry && (
        <TextInput
          name=""
          maxLength={14}
          description={entryDeviceSerialNum?.description}
          placeholder="Entry Device Serial Number"
          value={entryDeviceSerialNum?.value}
          error={entryDeviceSerialNum?.error}
          onChange={(e) => handleSerialDescription(e.target.value, 'entry')}
        />
      )}
      {configuration.formOptions.exit && (
        <TextInput
          name=""
          maxLength={14}
          description={exitDeviceSerialNum?.description}
          placeholder="Exit Device Serial Number"
          value={exitDeviceSerialNum?.value}
          error={exitDeviceSerialNum?.error}
          onChange={(e) => handleSerialDescription(e.target.value, 'exit')}
        />
      )}
      {entryDeviceSerialNum?.value?.substring(0, 4) !==
        exitDeviceSerialNum?.value?.substring(0, 4) &&
        configuration?.formOptions?.exit && (
          <p className={styles.access_alert}>
            Your entry device and exit device serial numbers are not matching
          </p>
        )}

      {entryDeviceSerialNum?.value === exitDeviceSerialNum?.value &&
        configuration?.formOptions?.exit && (
          <p className={styles.access_alert}>
            Your entry device and exit device cannot be the same
          </p>
        )}

      {configuration.formOptions.control && (
        <TextInput
          name=""
          maxLength={14}
          description={controllerDeviceSerialNum?.description}
          placeholder="Controller Device Serial Number"
          value={controllerDeviceSerialNum?.value}
          error={controllerDeviceSerialNum?.error}
          onChange={(e) => handleSerialDescription(e.target.value, 'controller')}
        />
      )}
      <div className={styles['separator']}>
        <hr />
      </div>
      {configuration.formOptions.lockingMechanism && (
        <div className={styles['dropdown-container']}>
          <Dropdown
            placeholder="Locking Mechanism"
            value={lockingMech?.name}
            onChange={selectLockingMechanism}
            data={Object.values(lockingMechanisms)}
          />
        </div>
      )}
      {renderRelayTime()}

      <div className={styles.border} />
      {/* {configuration?.formOptions?.doorSensor && <div className={styles.row}>
        <div className={styles.column}>
          <p className={styles.enabledText}>Enable Door Sensors</p>
        </div>
        <div className={styles.column}>
          <Switch
            checked={doorSensorEnabled}
            onChange={() => setDoorSensorEnabled(!doorSensorEnabled)}
          />
        </div>
      </div>} */}

      {/* {doorSensorEnabled && <div className={styles.door_sensor}>
        <h1 className={styles.title}>
          Door Sensor Configuration
        </h1>
        <div className={styles.outer_container}>
          <div className={styles.inner_container}>
            <Radio
              color="primary"
              value="NO"
              checked={normallyOpen}
              onChange={() => { setNormallyOpen(true) }}
            />
            <p>Normally Open (N.O.)</p>
          </div>

          <div className={styles.inner_container}>
            <Radio
              color="primary"
              value="NC"
              checked={!normallyOpen}
              onChange={() => { setNormallyOpen(false) }}
            />
            <p>Normally Closed (N.C.)</p>
          </div>
        </div>
        <p className={styles.note}>Note : Door sensor should be connected to entry reader</p>
      </div>} */}

      {configuration?.formOptions?.attendanceAvailable && (
        <>
          <div className={styles.row}>
            <div className={styles.column}>
              <p className={styles.enabledText}>Enable Attendance for device</p>
            </div>
            <div className={styles.column}>
              <Switch
                checked={attendanceEnabled}
                onChange={() => setAttendanceEnabled(!attendanceEnabled)}
              />
            </div>
          </div>
        </>
      )}
      <div className={styles['button-container']}>
        <div className={styles['button_width']}>
          <Button text="Cancel" buttonType="disabled" onClick={onClose} />
        </div>

        <div className={styles['button_width']}>
          <Button text="Back" onClick={handleClick} />
        </div>

        <div className={styles['button_width']}>
          <Button text="Add" onClick={handleSubmit} disabled={disabled} />
        </div>
      </div>
    </div>
  );
};

export default AccessPointConfiguration;
