import React from 'react';
import PropTypes from 'prop-types';
import styles from './Radio.module.scss';

const Radio = ({ checked = false, onChange, disabled = false, ...otherProps }) => {
  return (
    <label class={styles['container']}>
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...otherProps}
      />
      <span class={styles['checkmark']} disabled={disabled}></span>
    </label>
  );
};

Radio.protoTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Radio;
