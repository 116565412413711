import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import {
  selectOrganisationAccessPoints,
  selectOrganisationSites,
} from '../../../../../redux/selectors/organisation';
import { selectCurrentPartner } from '../../../../../redux/selectors/authentication';
import { useParams } from 'react-router-dom';
import { Search, SearchStatus } from '../../../../../utils/Search';
import AddOrEditAccessPointModal1 from './AddOrEditAccessPointModal';

import Button from '../../../../../components/Buttons/Button';
import Table from '../../../../../components/Table/Table';
import SquareDelete from '../../../../../components/SquareDelete/SquareDelete';

import {
  doDeleteOrganisationAccessPoint,
  doReadOrganisationAccessPoints,
} from '../../../../../redux/thunks/organisation';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
// import tableConfig from './tableConfig';
// import { statusRenderer } from '../../../../../helpers/renderers';
// import { statusSearcher } from '../../../../../helpers/searchers';
import SquareEdit from '../../../../../components/SquareEdit/SquareEdit';
import ModalAlert from '../../../../../components/Modals/ModalAlert';
import { configTypeRender } from '../../../../../helpers/renderers';

const AccessPointTab = () => {
  const dispatch = useDispatch();
  const [popupTitle, setPopupTitle] = useState('Add');
  const [showAccessPointPopup, setShowAccessPointPopup] = useState(false);
  const [showDeleteAccessPointPopup, setShowDeleteAccessPointPopup] = useState(false);
  const [accessPoint, setAccessPoint] = useState(null);
  const [filters, setFilters] = useImmer({
    name: '',
    id: '',
    siteName: '',
    configuration: '',
    networkName: '',
    status: '',
  });
  const [pagination, setPagination] = useImmer({ total: 0, currentPage: 1, perPage: 7 });

  const { customerId } = useParams();

  const { accessPoints, currentUser, sites } = useSelector((state) => ({
    accessPoints: selectOrganisationAccessPoints(state),
    currentUser: selectCurrentPartner(state),
    sites: selectOrganisationSites(state),
  }));

  useEffect(() => {
    setPagination((draft) => {
      draft.total = accessPoints?.length ? accessPoints?.length : 0;
    });
  }, [accessPoints?.length, setPagination]);

  // const getSiteId = (id) => {
  //   const site = sites.find((item) => item.id === id);
  //   return site?.name ? site?.name : '';
  // };

  const columns = [
    { title: 'Id', field: 'id', name: 'id' },
    {
      title: 'Access Point Name',
      field: 'name',
      name: 'name',
    },
    {
      title: 'Site',
      name: 'siteName',
      render: (columnProps, rowData) => {
        return rowData.siteName;
      },
    },
    {
      title: 'Network',
      name: 'networkName',
      render: (columnProps, rowData) => {
        return rowData.networkName;
      },
    },
    {
      title: 'Status',
      field: 'resourceState',
      render: (columnProps, rowData) => {
        return rowData.resourceState;
      },
      name: 'status',
    },
    {
      title: 'Configuration',
      field: 'configuration',
      render: (columnProps, rowData) => {
        return configTypeRender(rowData.configuration);
      },
    },
    {
      title: 'Action',
      render: (columnProps, rowData, row) => {
        return (
          <div className="button-row">
            <SquareEdit
              color={'#ff535a'}
              onPress={() => {
                setPopupTitle('Edit');
                setAccessPoint(rowData);
                togglePopup('AddOrEditAccessPoint');
              }}
            />
            <SquareDelete
              color={'#ff535a'}
              onPress={() => {
                setPopupTitle('Delete');
                setAccessPoint(rowData);
                togglePopup('DeleteAccessPoint');
              }}
            />
          </div>
        );
      },
      // customFilterAndSearch: (term, row) => statusSearcher(term, row.cloudStatus),
    },
  ];

  const togglePopup = (popupName) => {
    if (popupName === 'AddOrEditAccessPoint') {
      setShowAccessPointPopup(!showAccessPointPopup);
      if (showAccessPointPopup) {
        setAccessPoint(null);
      }
    }

    if (popupName === 'DeleteAccessPoint') {
      setShowDeleteAccessPointPopup(!showDeleteAccessPointPopup);
      if (showDeleteAccessPointPopup) {
        setAccessPoint(null);
      }
    }
  };

  const point = useBreakpoint();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (currentUser)
      dispatch(
        doReadOrganisationAccessPoints({
          partnerId: currentUser.partnerId,
          organisationId: customerId,
          displayNotification: false,
        }),
      );
  }, [dispatch, point, currentUser, customerId]);

  const handleDeleteAccessPoint = () => {
    dispatch(
      doDeleteOrganisationAccessPoint({
        partnerId: currentUser.partnerId,
        organisationId: customerId,
        accessPointId: accessPoint.id,
      }),
    );
    setAccessPoint(null);
    setShowDeleteAccessPointPopup(false);
  };

  const hSearchChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilters((draft) => {
      draft[name] = value;
    });
  };

  const search = {
    onChange: hSearchChange,
  };

  // const scrapeData = accessPoints?.filter((item) => {
  //   const configuration = item.configuration;
  //   return configuration !== 3 && configuration !== 4;
  // });

  const filteredData = useCallback(() => {
    let data = accessPoints ? accessPoints : [];
    if (filters.id !== '') {
      data = Search(data, 'id', filters.id);
    }
    if (filters.name !== '') {
      data = Search(data, 'name', filters.name);
    }
    if (filters.siteName !== '') {
      data = Search(data, 'siteName', filters.siteName);
    }
    if (filters.networkName !== '') {
      data = Search(data, 'networkName', filters.networkName);
    }
    if (filters.configuration !== '') {
      data = Search(data, 'configuration', filters.configuration);
    }
    if (filters.status !== '') {
      data = SearchStatus(data, 'resourceState', filters.status);
    }

    data = data.slice(
      (pagination.currentPage - 1) * 7,
      pagination.currentPage * pagination.perPage,
    );
    return data;
  }, [filters, pagination.currentPage, pagination.perPage, accessPoints]);

  const changePage = (p) => {
    const { page, per_page } = p;

    setPagination((draft) => {
      draft.currentPage = page;
      draft.perPage = per_page;
    });
  };

  return (
    <Fragment>
      <Grid container className="spacer__1" />
      <Grid container>
        {showAccessPointPopup && (
          <AddOrEditAccessPointModal1
            {...{
              popupTitle,
              showAccessPointPopup,
              togglePopup,
              accessPoint,
              setAccessPoint,
              mode: popupTitle?.toLocaleLowerCase(),
              currentUser,
              customerId,
            }}
          />
        )}
        {showDeleteAccessPointPopup && (
          <ModalAlert
            showModal={showDeleteAccessPointPopup}
            closeModal={() => {
              setShowDeleteAccessPointPopup(false);
            }}
            title={'Delete Access Point/ s'}
            description={`Are you sure you want to delete ${accessPoint ? accessPoint.name : ''} ? This
            action is non reversible and will cause you to lose all data associated with this
            AccessPoint/ s`}
            modalType="danger"
            handleSubmit={handleDeleteAccessPoint}
          />
        )}

        <div className="header-container">
          <p className="header">Access Points</p>
          <div className="button-cont">
            <Button
              text={'Add Access Points'}
              onClick={() => {
                setPopupTitle('Add');
                togglePopup('AddOrEditAccessPoint');
              }}
              disabled={sites == null || sites.length === 0}
            />
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table
            columns={columns}
            data={filteredData()}
            defaultMessage={'No Access Points Found'}
            pagination={pagination}
            options={{ search }}
            changePage={changePage}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AccessPointTab;
